import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import type { FeedItemType, PaginatedNewsFeedByItemQueryVariables } from '@news/gql';
import { Category, usePaginatedNewsFeedByItemQuery } from '@news/gql';
import {
  FALLBACK_OG_IMAGE,
  getEmptyPaginatedFeed,
  getMetaTitle,
  getOgDescription,
  getOgTitle,
  getVideoAssetIds,
  isNotDeprecatedNewsFeedItem,
  isTypeNewsEntry,
} from '@news/lib';
import { getFeedItemPageType, getNewsEntryKilkayaPageData, getTagsForItem } from '@news/tracking';

import { OgTags } from 'components/OgTags';
import { AppLinksMeta } from 'components/meta/applinks';
import { Redirect } from 'components/redirect';
import { NewsEntrySchema } from 'components/schema/NewsEntrySchema';
import { usePageContext } from 'contexts/PageContext';
import { notifyBugsnag } from 'lib/bugsnagClient';
import { getRedirectPathForItem } from 'lib/helpers';
import { ImageProxy } from 'lib/imageProxy';

import { LoadMoreButton } from '../feed/components/loadMoreButton';
import { feedItemImage } from './helpers';

const Loading = dynamic(() => import('components/loading').then((module) => module.Loading));
const CustomError = dynamic(() => import('components/error').then((module) => module.CustomError));
const NewsFeedContainer = dynamic(() => import('components/newsfeed').then((module) => module.NewsFeed));
const Error404 = dynamic(() => import('views/error/404').then((module) => module.Error404));

interface FeedItemProps {
  id: string;
  type: FeedItemType;
  slug?: string;
}

let category = Category.News;

const FeedItem = ({ id, type, slug }: FeedItemProps) => {
  const { updatePageInfo } = usePageContext();
  const router = useRouter();

  const variables: PaginatedNewsFeedByItemQueryVariables = {
    id,
    type,
    page: 0,
    excludeVideoAssetList: [],
    limit: 5,
  };

  const { data, called, loading, error, fetchMore } = usePaginatedNewsFeedByItemQuery({
    variables,
  });

  const nextPage = data?.paginatedNewsFeedByItem?.newsFeed?.feed?.pagingData?.nextPage ?? 1;

  const loadMore = () => {
    if (nextPage === -1) {
      return false;
    }
    const items = data?.paginatedNewsFeedByItem?.newsFeed?.feed?.items.filter(isNotDeprecatedNewsFeedItem) ?? [];
    const excludeVideoList = getVideoAssetIds(items);
    fetchMore({
      variables: {
        ...variables,
        page: nextPage,
        excludeVideoAssetList: excludeVideoList,
      },
    });
  };

  const item = data?.paginatedNewsFeedByItem?.item;

  const newsFeed = data?.paginatedNewsFeedByItem?.newsFeed ?? getEmptyPaginatedFeed();

  if (newsFeed) {
    category = newsFeed.category ?? category;
  }

  const isLoading = !called || loading;

  useEffect(() => {
    if (!item || !newsFeed) {
      return;
    }
    updatePageInfo({
      pageData: {
        page_path: router.asPath,
        page_id: id,
        page_title: item.title,
        page_type: getFeedItemPageType(item),
        page_tags: getTagsForItem(item),
        page_feed: newsFeed.slug,
      },
      kilkayaData: getNewsEntryKilkayaPageData(item),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, item?.id, newsFeed?.id, updatePageInfo]);

  if (isLoading) {
    return <Loading />;
  } else if (error) {
    notifyBugsnag({ error, slug, query: 'usePaginatedNewsFeedByItem' });
    return <CustomError statusCode={500} />;
  } else if (!item) {
    return <Error404 />;
  }

  if (isTypeNewsEntry(item) && router.query.id === item.id && item.slug !== router.query.slug) {
    const newPath = getRedirectPathForItem(item, router.asPath);
    return <Redirect to={newPath} />;
  }

  const loadMoreButton = nextPage === -1 ? null : <LoadMoreButton nextPage={nextPage} onClick={loadMore} />;

  return (
    <>
      <Head>
        <title>{getMetaTitle(item)}</title>
        <meta name="description" content={getOgDescription(item)} />
      </Head>
      <OgTags
        title={getOgTitle(item)}
        description={getOgDescription(item)}
        image={feedItemImage(item) ?? ImageProxy.url({ url: FALLBACK_OG_IMAGE, width: 1200 })}
      />
      <AppLinksMeta />
      <NewsEntrySchema item={item} />
      <NewsFeedContainer loadMoreButton={loadMoreButton} newsFeed={newsFeed} item={item} />
    </>
  );
};

export { FeedItem };
